<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'

export default {
  name: 'Planner',
  components: { Layout },
  created () {
    this.$router.replace({ name: 'customize' })
  },
}
</script>
