<template>
  <div class="home">
    <div class="grid-x grid-padding-x">
      <div class="cell">

        <div class="grid-x grid-padding-x">

          <div class="cell customize">

            <h2>Customize your ride</h2>
            <p class="h3">Let Caledon Community Services' Transportation help you get to where you need to be!</p>

            <!-- <div class="buttons row"> -->
            <div class="small-12 cell field">
              <label>Are you a representative booking this ride for a client? (required)</label>
              <button type="button" @click="form.thirdParty.isThirdParty = true; validateField('thirdParty', {target: {value: true}})" :class="{selected: form.thirdParty.isThirdParty}" class="selectable">Yes</button>
              <button type="button" @click="form.thirdParty.isThirdParty = false; validateField('thirdParty', {target: {value: true}})" :class="{selected: form.thirdParty.isThirdParty === false}"         class="selectable">No</button>
                <span v-show="!fields.thirdParty.valid" class="error">{{ fields.thirdParty.message }}</span>
              <div v-if="form.thirdParty.isThirdParty">
                <div class="cell tpfirstname field">
                  <label>First Name (required)</label>
                  <input v-model="form.thirdParty.firstName" @blur="validateField('thirdParty.firstName', $event)" :class="{invalid: !fields.thirdPartyfirstName.valid}" type="text" placeholder="First Name"/>
                  <span v-show="!fields.thirdPartyfirstName.valid" class="error">{{ fields.thirdPartyfirstName.message }}</span>
                </div>
                <div class="cell tplastname field">
                  <label>Last Name (required)</label>
                  <input v-model="form.thirdParty.lastName" @blur="validateField('thirdParty.lastName', $event)" :class="{invalid: !fields.thirdPartylastName.valid}" type="text" placeholder="Last Name"/>
                  <span v-show="!fields.thirdPartylastName.valid" class="error">{{ fields.thirdPartylastName.message }}</span>
                </div>
                <div class="cell tpphone field">
                  <label>Phone Number (required)</label>
                  <Cleave
                    :raw="false"
                    v-model="form.thirdParty.phone"
                    placeholder="Phone Number"
                    class="small input-group-field"
                    :class="{invalid: !fields.thirdPartyphone.valid}"
                    type="tel"
                    @blur="validateField('thirdParty.phone', $event)"
                    :options="{ blocks: [3, 3, 4], delimiter: '-' }"
                  />
                  <!-- <div class="input-group input-group-rounded">
                    <span class="input-group-label" :class="{invalid: !fields.thirdPartyphone.valid}">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span> -->
                    <!-- <input v-model="form.thirdParty.phone" @click="inputPhone" @blur="validateField('thirdParty.phone', $event)" class="input-group-field" :class="{invalid: !fields.thirdPartyphone.valid}"         type="tel" id="phone" name="phone" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/> -->
                    <p class="sml" style="margin-bottom:0;">Format: 123-456-7890</p>
                  <!-- </div> -->
                  <span v-show="!fields.thirdPartyphone.valid" class="error">{{ fields.thirdPartyphone.message }}</span>
                </div>
                <div class="cell tpemail field">
                  <label>Email Address</label>
                  <!-- <div class="input-group input-group-rounded">
                    <span class="input-group-label" :class="{invalid: !fields.thirdPartyemail.valid}">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </span> -->
                    <!-- <input v-model="form.thirdParty.email" @blur="validateField('thirdParty.email', $event)" class="input-group-field" :class="{invalid: !fields.thirdPartyemail.valid}" type="email" id="email"         name="email" placeholder="Email Address"/> -->
                    <input v-model="form.thirdParty.email" type="email" id="email" name="email" placeholder="Email Address"/>
                  <!-- </div> -->
                </div>

              </div>
            </div>
            <!-- <div class="buttons row"> -->
            <div class="small-12 cell field">
              <label>Will an escort/support person be needed for this ride? (required)<ToolTip tip="A support person/escort accompanies a rider who may not be able to perform tasks without assistance.<br>*No Charge<br><br>Example: Medical assistance, or translator." /></label>
              <button type="button" @click="setInfo('escort', true)" :class="{selected: form.escort}" class="selectable">Yes</button>
              <button type="button" @click="setInfo('escort', false)" :class="{selected: form.escort === false}" class="selectable">No</button>
              <span v-show="!fields.escort.valid" class="error">{{ fields.escort.message }}</span>
            </div>
            <!-- <div class="buttons row"> -->
            <div class="small-12 cell field">
              <label>Do you want to add a companion to this ride? (required)<ToolTip tip="A companion is an additional rider who is going to the same destination as the primary account holder.<br>*Additional Charge<br><br>Example: Client taking their children/spouse." /></label>
              <button type="button" @click="companion = true" :class="{selected: companion}" class="selectable">Yes</button>
              <button type="button" @click="companion = false; setInfo('companion', 0);" :class="{selected: companion === false}" class="selectable">No</button>
              <span v-show="!companion && !fields.companion.valid" class="error">{{ fields.companion.message[0] }}</span>
              <div v-if="companion" class="buttons row" style="margin-top: 2.5rem;">
                <label>How many extra seats are needed (1 seat per companion)? (required)</label>
                <button type="button" @click="setInfo('companion', 1)" :class="{selected: form.companion === 1}" class="selectable">1</button>
                <button type="button" @click="setInfo('companion', 2)" :class="{selected: form.companion === 2}" class="selectable">2</button>
                <span v-show="!fields.companion.valid" class="error">{{ fields.companion.message[1] }}</span>
              </div>
            </div>
            <!-- <div class="row"> -->
            <div class="small-12 cell field">
              <label>What is the purpose of your ride? (required)</label>
              <select @change="setPurpose" ref="purposeselect" :class="{invalid: !fields.purpose.valid}">
                <option>Select...</option>
                <option v-for="purpose in purposes" :key="purpose">{{ purpose }}</option>
              </select>
              <span v-show="!fields.purpose.valid" class="error">{{ fields.purpose.message }}</span>
            </div>
            <div v-if="otherPurpose" class="small-12 cell purposeOther field">
              <label>Please enter the purpose of your ride (required)</label>
              <input @input="setPurpose" @blur="validateField('purpose', $event)" :class="{invalid: !fields.purpose.valid}" type="text" placeholder="Other Purpose"/>
              <span v-show="!fields.purpose.valid" class="error">{{ fields.purpose.message }}</span>
            </div>
            <!-- <div class="row"> -->
            <div class="small-12 cell field">
              <label>Will you be using any mobility aids? (required)</label>
              <select @change="setMobility" @blur="validateField('mobilityAid', $event)" :class="{invalid: !fields.mobilityAid.valid}" ref="mobilityselect" placeholder="None">
                <option>Select...</option>
                <option>None</option>
                <option>Wheelchair</option>
                <option>Walker</option>
                <option>Cane</option>
              </select>
              <span v-show="!fields.mobilityAid.valid" class="error">{{ fields.mobilityAid.message }}</span>
            </div>

          </div>

        </div>

        <!-- <button type="button" @click="next">Next</button> -->
        <button type="button" class="primary btn button float-right" @click.prevent="next()">NEXT <img src="../assets/next.svg" alt="Next"></button>

      </div>

    </div>

  </div>
</template>

<script>
// import axios from 'axios'
// import Cleave from 'cleave.js'
import Cleave from 'vue-cleave-component'
import { useInfo, useRide, validateField } from '../store'
import ToolTip from '@/components/ToolTip.vue'

export default {
  name: 'RideMeta',
  components: {
    ToolTip,
    Cleave
  },
  created () {
    if (!this.infoState.info.account) this.$router.replace('/')
    // in case we come back to edit
  },
  mounted () {
    if (this.rideState.ride.price) {
      this.form.purpose = this.rideState.ride.purpose
      if (this.form.purpose && !this.purposes.includes(this.rideState.ride.purpose)) this.otherPurpose = true
      this.form.mobilityAid = this.rideState.ride.mobilityAid
      this.form.companion = this.rideState.ride.companion
      this.form.escort = this.rideState.ride.escort
      this.form.thirdParty = this.rideState.ride.thirdParty
      this.form.thirdParty = this.rideState.ride.thirdParty

      this.$nextTick(() => {
        if (this.form.companion) this.companion = true
        this.$refs.purposeselect.value = this.form.otherPurpose ? 'Other' : this.form.purpose
        this.$refs.mobilityselect.value = this.form.mobilityAid

        this.validate()
      })
    }
  },
  data () {
    return {
      infoState: useInfo(),
      rideState: useRide(),
      companion: undefined,
      otherPurpose: false,
      purposes: [
        'Medical',
        'Grocery Shopping',
        'Work',
        'School Programs',
        'Community Partnership Programs',
        'Volunteer Initiatives',
        'Exchanges',
        'Evolve',
        'Other'
      ],
      form: {
        purpose: undefined,
        mobilityAid: undefined,
        companion: 0,
        escort: undefined,
        thirdParty: {
          isThirdParty: undefined,
          firstName: '',
          lastName: '',
          phone: '',
          email: ''
        },
      },
      fields: {
        purpose: {
          valid: true,
          blurred: false,
          message: 'Need a Purpose',
          rules: ['required']
        },
        mobilityAid: {
          valid: true,
          blurred: false,
          message: 'Must choose an option',
          rules: ['required']
        },
        thirdParty: {
          valid: true,
          blurred: false,
          message: 'Must choose Yes or No',
          rules: ['required']
        },
        thirdPartyfirstName: {
          valid: true,
          blurred: false,
          message: 'Need a First Name',
          rules: ['required']
        },
        thirdPartylastName: {
          valid: true,
          blurred: false,
          message: 'Need a Last Name',
          rules: ['required']
        },
        thirdPartyphone: {
          valid: true,
          blurred: false,
          message: 'Need a Phone No.',
          rules: ['required']
        },
        thirdPartyemail: {
          valid: true,
          blurred: false,
          message: 'Need an Email',
          rules: ['required']
        },
        escort: {
          valid: true,
          blurred: false,
          message: 'Must choose Yes or No',
          rules: ['required']
        },
        companion: {
          valid: true,
          blurred: false,
          message: ['Must choose yes or no', 'Must choose a number'],
          rules: ['required']
        },
      },
      invalid: true,
      validityFlags: [],
      showInvalidSummary: false,
      invalidMessages: []
    }
  },
  methods: {
    setInfo (prop, val) {
      this.form[prop] = val
      this.validateField(prop, { target: { value: val } })
    },
    setPurpose (evt) {
      this.form.purpose = evt.target.value
      if (evt.target.value === 'Other') {
        this.otherPurpose = true
      } else if (this.purposes.includes(evt.target.value)) {
        this.otherPurpose = false
      }
      this.validateField('purpose', evt)
    },
    setMobility (evt) {
      this.form.mobilityAid = evt.target.value
    },
    // inputPhone (evt) {
    //   const c = new Cleave(evt.target, { blocks: [3, 3, 4], delimiter: '-' }) // eslint ignore no-new
    //   if (!c) console.info(' ::: cleave not set?')
    // },
    validateField (fieldkey, evt) {
      // console.info(' ::: validate field', fieldkey)
      if (typeof evt === 'string') evt = { target: { value: evt } }
      this.fields[fieldkey].blurred = true
      this.fields[fieldkey].valid = validateField(fieldkey, evt, this.fields)
      this.validate(evt)
    },
    validate (evt) {
      let valid = true
      this.validityFlags = []
      this.$nextTick(() => {
        if (!this.form.purpose || this.form.purpose === 'Other') {
          valid = false
          this.validityFlags.push('purpose')
        } else {
          this.fields.purpose.valid = true
        }
        if (this.form.escort === undefined) {
          valid = false
          this.validityFlags.push('escort')
        } else {
          this.fields.escort.valid = true
        }
        if (this.form.mobilityAid === undefined) {
          valid = false
          this.validityFlags.push('mobilityAid')
        } else {
          this.fields.mobilityAid.valid = true
        }
        if (this.companion === undefined || (this.companion && !this.form.companion)) {
          valid = false
          this.validityFlags.push('companion')
        } else {
          this.fields.companion.valid = true
        }
        if (this.form.thirdParty.isThirdParty === undefined) {
          valid = false
          this.validityFlags.push('thirdParty')
        } else {
          this.fields.thirdParty.valid = true
        }
        if (this.form.thirdParty.isThirdParty && !this.form.thirdParty.firstName) {
          valid = false
          this.validityFlags.push('thirdPartyfirstName')
        }
        if (this.form.thirdParty.isThirdParty && !this.form.thirdParty.lastName) {
          valid = false
          this.validityFlags.push('thirdPartylastName')
        }
        if (this.form.thirdParty.isThirdParty && !this.form.thirdParty.phone) {
          valid = false
          this.validityFlags.push('thirdPartyphone')
        }
        // if (this.form.thirdParty.isThirdParty && !this.form.thirdParty.email) {
        //   valid = false
        //   this.validityFlags.push('thirdPartyemail')
        // }

        this.invalid = !valid
        if (this.showInvalidSummary && valid) this.showInvalidSummary = false
        return Promise.resolve(valid)
      })
    },
    next () {
      if (this.invalid) {
        if (!this.validityFlags.length) this.validate()
        this.$nextTick(() => {
          this.invalidMessages = this.validityFlags.map(f => ({ field: f, message: this.fields[f].message }))
          for (const field of this.invalidMessages) {
            this.fields[field.field].valid = false
          }
        })
        this.showInvalidSummary = true
        // return
      } else {
        // if (this.infoState.info.type !== 'transHelp') {
        //   const price = await axios.post('/booking/price', this.form)
        //   this.form.price = price.data.total
        // }
        // // console.info(' ::: next and valid', this.form, this.infoState.info.value)
        // this.form.arriveTimestamp = new Date(this.form.date + ' ' + this.form.time).getTime()
        // if (this.form.returnTrip) this.form.pickupTimestamp = new Date(this.form.date + ' ' + this.form.pickupTime).getTime()
        // set form info
        this.rideState.setRide(this.form)
        this.$router.push({ name: 'details' })
      }
    }
  }
}
</script>
