<template>
  <div class="home">
    <div class="grid-x grid-padding-x">
      <div class="cell">

        <div class="grid-x grid-padding-x">

          <div class="cell book">

            <h2>Let’s book your ride!</h2>

            <div class="medium-5 large-4 cell field">
              <label>Choose the date of your ride <ToolTip tip="Can’t find your available date?<br>You might be booking too late or too early.<br><br>When booking for the date of your ride, it needs to be booked 2 full business days ahead and can only be booked 7 days in advance. This way we can accommodate for everyone." /></label>
              <select v-model="form.date" @change="validateField('date', $event)" :class="{invalid: !fields.date.valid}">
                <option selected="true" value="" class="disabled">Date</option>
                <option v-for="date in availableDates" :key="date" :value="date">{{ date }}</option>
              </select>
              <span v-show="!fields.date.valid" class="error">{{ fields.date.message }}</span>
            </div>

            <hr />

            <div class="cell trip">

              <div class="cell start">

                <div class="grid-x grid-padding-x">

                  <div class="small-2 medium-1 cell marker orig">
                    <p></p>
                  </div>

                  <div class="small-10 medium-11 cell segment">

                    <h2>Specify the origin address</h2>
                    <h3>Where are we picking you up?</h3>

                    <div class="grid-x grid-padding-x">

                      <div class="medium-4 large-3 cell field">
                        <label>Street Number</label>
                        <input type="text" class="small" v-model="form.startAddress.number" @blur="validateField('startAddress', $event)" :class="{invalid: !fields.startAddress.valid}" placeholder="Street Number" />
                        <span v-show="!fields.startAddress.valid" class="error">{{ fields.startAddress.message }}</span>
                      </div>
                      <div class="medium-8 large-9 cell field">
                        <label>Street Name</label>
                        <input type="text" v-model="form.startAddress.street" @blur="validateField('startAddress', $event)" :class="{invalid: !fields.startAddress.valid}" placeholder="Street Name" />
                        <span v-show="!fields.startAddress.valid" class="error">{{ fields.startAddress.message }}</span>
                      </div>
                      <div class="medium-4 large-3 cell field">
                        <label>Apt/Unit Number</label>
                        <input type="text" class="small" v-model="form.startAddress.apt" placeholder="Apt/Unit Number" />
                        <!-- <span v-show="!fields.startAddress.valid" class="error">{{ fields.startAddress.message }}</span> -->
                      </div>
                      <div class="medium-8 large-9 cell field">
                        <label>City/Town</label>
                        <select v-model="form.startAddress.city" @change="validateField('startAddress', $event)" :class="{invalid: !fields.startAddress.valid}" >
                          <option v-for="origin in origins" :key="origin.name" :value="origin.name">{{ origin.label }}</option>
                        </select>
                        <span v-show="!fields.startAddress.valid" class="error">{{ fields.startAddress.message }}</span>
                        <p class="red sml" style="margin-bottom:0;">*Within the Town of Caledon</p>
                      </div>
                      <div class="medium-4 large-3 cell field">
                        <label>Postal Code</label>
                        <Cleave
                          :raw="false"
                          v-model="form.startAddress.postalCode"
                          placeholder="Postal Code"
                          class="small"
                          @blur="validateField('startAddress', $event)"
                          :options="{ blocks: [3, 3], delimiter: ' ', uppercase: true }"
                        />
                        <!-- <input type="text" class="small" @input="inputPostalCode" v-model="form.startAddress.postalCode" placeholder="Postal Code" /> -->
                        <span v-show="!fields.startAddress.valid" class="error">{{ fields.startAddress.message }}</span>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div class="cell end">

                <div class="grid-x grid-padding-x">

                  <div class="small-2 medium-1 cell marker dest">
                    <p></p>
                  </div>

                  <div class="small-10 medium-11 cell segment">

                    <h2>Specify the destination address</h2>
                    <h3>Where are we dropping you off?</h3>

                    <div class="grid-x grid-padding-x">

                      <div class="small-12 cell field">
                          <label>Arrival Time</label>
                          <select v-model="form.time" @change="validateField('time', $event)" :class="{invalid: !fields.time.valid}" >
                            <option value="">Arrival Time</option>
                            <option v-for="time in pickupTimes" :key="time" :value="time">{{ time }}</option>
                          </select>
                          <span v-show="!fields.time.valid" class="error">{{ fields.time.message }}</span>
                        <!-- </div> -->
                      </div>
                      <div class="medium-4 large-3 cell field">
                        <label>Street Number</label>
                        <input type="text" class="small" v-model="form.destinationAddress.number" @blur="validateField('destinationAddress', $event)" :class="{invalid: !fields.destinationAddress.valid}" placeholder="Street Number" />
                        <span v-show="!fields.destinationAddress.valid" class="error">{{ fields.destinationAddress.message }}</span>
                      </div>
                      <div class="medium-8 large-9 end cell field">
                        <label>Street Name</label>
                        <input type="text" v-model="form.destinationAddress.street" @blur="validateField('destinationAddress', $event)" :class="{invalid: !fields.destinationAddress.valid}" placeholder="Street Name" />
                        <span v-show="!fields.destinationAddress.valid" class="error">{{ fields.destinationAddress.message }}</span>
                      </div>
                      <div class="medium-4 large-3 cell field">
                        <label>Apt/Unit Number</label>
                        <input type="text" class="small" v-model="form.destinationAddress.apt" placeholder="Apt/Unit Number" />
                        <!-- <span v-show="!fields.destinationAddress.valid" class="error">{{ fields.destinationAddress.message }}</span> -->
                      </div>
                      <div class="medium-8 large-9 end cell field">
                        <label>City/Town</label>
                        <select v-model="form.destinationAddress.city" @change="validateField('destinationAddress', $event)" :class="{invalid: !fields.destinationAddress.valid}" >
                          <option v-for="city in cities" :key="city.name" :value="city.name">{{ city.label }}</option>
                        </select>
                        <span v-show="!fields.destinationAddress.valid" class="error">{{ fields.destinationAddress.message }}</span>
                      </div>
                      <div class="medium-4 large-3 cell field">
                        <label>Postal Code</label>
                        <Cleave
                          :raw="false"
                          v-model="form.destinationAddress.postalCode"
                          placeholder="Postal Code"
                          class="small"
                          @blur="validateField('destinationAddress', $event)"
                          :options="{ blocks: [3, 3], delimiter: ' ', uppercase: true }"
                        />
                        <!-- <input type="text" class="small" @input="inputPostalCode" v-model="form.destinationAddress.postalCode" placeholder="Postal Code" /> -->
                        <span v-show="!fields.destinationAddress.valid" class="error">{{ fields.destinationAddress.message }}</span>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

            <hr />

            <div class="medium-8 large-6 end cell field">
              <label>Is a return trip required?</label>
              <button type="button" @click="form.returnTrip = true" :class="{selected: form.returnTrip}" class="selectable">Yes</button>
              <button type="button" @click="form.returnTrip = false" :class="{selected: form.returnTrip === false}" class="selectable">No</button>
            </div>

            <template v-if="form.returnTrip">

              <div class="cell trip">

                <h2>Let's book your return trip</h2>
                <div class="cell start">
                  <div class="grid-x grid-padding-x">

                    <div class="small-2 medium-1 cell">
                      <p></p>
                    </div>

                    <div class="small-10 medium-11 cell segment">
                      <div class="grid-x grid-padding-x">
                        <div class="cell field">
                          <label>Do you want to be picked up where you were dropped off?</label>
                          <button type="button" @click="form.pickupAddress = 'same'" :class="{selected: form.pickupAddress === 'same'}" class="selectable">Yes</button>
                          <button type="button" @click="form.pickupAddress = form.pickupAddress === 'same' ? Object.create({...addressTpl, city: form.destinationAddress.city}) : form.pickupAddress" :class="{selected:form.pickupAddress !== 'same'}" class="selectable">No</button>
                        </div>
                        <div class="cell field">
                          <label>Pickup Time</label>
                          <select v-model="form.pickupTime" @change="validateField('pickupTime', $event)" :class="{invalid: !fields.pickupTime.valid}">
                            <option value="">Pickup Time</option>
                            <option v-for="time in pickupTimes" :key="time" :value="time">{{ time }}</option>
                          </select>
                          <span v-show="!fields.pickupTime.valid" class="error">{{ fields.pickupTime.message }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="form.pickupAddress === 'same'" class="small-2 medium-1 cell marker dest return">
                      <p></p>
                    </div>

                    <div v-if="form.pickupAddress === 'same'" class="small-10 medium-11 cell segment">
                      <h2>Return`s origin address</h2>
                      <label>{{ form.destinationAddress.number }} {{ form.destinationAddress.street }}, {{ form.destinationAddress.city }}, {{ form.destinationAddress.postalCode }}</label>
                    </div>

                    <div v-if="form.pickupAddress !== 'same'" class="small-2 medium-1 cell marker dest return">
                      <p></p>
                    </div>

                    <div v-if="form.pickupAddress !== 'same'" class="small-10 medium-11 cell segment">

                      <h2>Specify the return`s origin address</h2>
                      <p class="h3">Where are we picking you up?</p>

                      <div class="grid-x grid-padding-x">
                        <div class="medium-4 large-3 cell field">
                          <label>Street Number</label>
                          <input type="text" class="small" v-model="form.pickupAddress.number" @blur="validateField('pickupAddress', $event)" :class="{invalid: !fields.pickupAddress.valid}" placeholder="Street Number" />
                          <span v-show="!fields.pickupAddress.valid" class="error">{{ fields.pickupAddress.message }}</span>
                        </div>
                        <div class="medium-8 large-9 end cell field">
                          <label>Street Name</label>
                          <input type="text" v-model="form.pickupAddress.street" @blur="validateField('pickupAddress', $event)" :class="{invalid: !fields.pickupAddress.valid}" placeholder="Street Name" />
                          <span v-show="!fields.pickupAddress.valid" class="error">{{ fields.pickupAddress.message }}</span>
                        </div>
                        <div class="medium-4 large-3 cell field">
                          <label>Apt/Unit Number</label>
                          <input type="text" class="small" v-model="form.pickupAddress.apt" placeholder="Apt/Unit Number" />
                        </div>
                        <div class="medium-8 large-9 end cell field">
                          <label>City/Town</label>
                          <p class="h3">{{ form.pickupAddress.city }}</p>
                          <span v-show="!fields.pickupAddress.valid" class="error">{{ fields.pickupAddress.message }}</span>
                        </div>
                        <div class="medium-4 large-3 cell field">
                          <label>Postal Code</label>
                        <Cleave
                          :raw="false"
                          v-model="form.pickupAddress.postalCode"
                          placeholder="Postal Code"
                          class="small"
                          @blur="validateField('pickupAddress', $event)"
                          :options="{ blocks: [3, 3], delimiter: ' ', uppercase: true }"
                        />
                          <span v-show="!fields.pickupAddress.valid" class="error">{{ fields.pickupAddress.message }}</span>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div class="cell end">
                  <div class="grid-x grid-padding-x">
                    <div class="small-2 medium-1 cell marker path">
                      <p></p>
                    </div>
                    <div class="small-10 medium-11 cell field" style="margin-top: 2.5rem;">
                      <label>Do you want to be dropped off where you were picked up?</label>
                      <button type="button" @click="form.returnAddress = 'same'" :class="{selected: form.returnAddress === 'same'}"  class="selectable">Yes</button>
                      <button type="button" @click="form.returnAddress = form.returnAddress === 'same' ? Object.create({...addressTpl, city: form.startAddress.city}) : form.returnAddress" :class="{selected: form.returnAddress !== 'same'}" class="selectable">No</button>
                    </div>
                    <div v-if="form.returnAddress === 'same'" class="small-2 medium-1 cell marker orig return" style="margin-top: 0.8125rem;">
                      <p></p>
                    </div>
                    <div v-if="form.returnAddress === 'same'" class="small-10 medium-11 cell segment" style="margin-top: 0.8125rem;">
                      <h2>Return`s destination address</h2>
                      <label>{{ form.startAddress.number }} {{ form.startAddress.street }}, {{ form.startAddress.city }}, {{ form.startAddress.postalCode }}</label>
                    </div>
                    <div v-if="form.returnAddress !== 'same'" class="small-2 medium-1 cell marker orig return" style="margin-top: 0.8125rem;">
                      <p></p>
                    </div>
                    <div v-if="form.returnAddress !== 'same'" class="small-10 medium-11 cell segment" style="margin-top: 0.8125rem;">
                      <h2>Specify the return`s destination address</h2>
                      <p class="h3">Where are we dropping you off?</p>
                      <div class="grid-x grid-padding-x">
                        <div class="medium-4 large-3 cell field">
                          <label>Street Number</label>
                          <input type="text" class="small" v-model="form.returnAddress.number" @blur="validateField('returnAddress', $event)" :class="{invalid: !fields.returnAddress.valid}" placeholder="StreetNumber"        />
                          <span v-show="!fields.returnAddress.valid" class="error">{{ fields.returnAddress.message }}</span>
                        </div>
                        <div class="medium-8 large-9 cell field">
                          <label>Street Name</label>
                          <input type="text" v-model="form.returnAddress.street" @blur="validateField('returnAddress', $event)" :class="{invalid: !fields.returnAddress.valid}" placeholder="Street Name" />
                          <span v-show="!fields.returnAddress.valid" class="error">{{ fields.returnAddress.message }}</span>
                        </div>
                        <div class="medium-4 large-3 cell field">
                          <label>Apt/Unit Number</label>
                          <input type="text" class="small" v-model="form.returnAddress.apt" placeholder="Apt/Unit Number" />
                        </div>
                        <div class="medium-8 large-9 cell field">
                          <label>City/Town</label>
                          <p class="h3">{{ form.startAddress.city }}</p>
                          <span v-show="!fields.returnAddress.valid" class="error">{{ fields.returnAddress.message }}</span>
                        </div>
                        <div class="medium-4 large-3 cell field">
                          <label>Postal Code</label>
                          <Cleave
                            :raw="false"
                            v-model="form.returnAddress.postalCode"
                            placeholder="Postal Code"
                            class="small"
                            @blur="validateField('returnAddress', $event)"
                            :options="{ blocks: [3, 3], delimiter: ' ', uppercase: true }"
                          />
                          <span v-show="!fields.returnAddress.valid" class="error">{{ fields.returnAddress.message }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </template>

            <hr />

            <h3>Is there anything else you'd like us to know?</h3>

            <div class="medium-7 large-8 cell field">
              <label>Special instructions</label>
              <textarea v-model="form.instructions" rows="4" placeholder="Special instructions"></textarea>
            </div>

          </div>

        </div>

        <button type="button" :disabled="showInvalidSummary" :class="{disabled: showInvalidSummary}" class="primary btn button float-right" @click.prevent="next()">NEXT <img src="../assets/next.svg" alt="Next"></button>

      </div>

    </div>

  </div>
</template>

<script>
// import this.axios from 'this.axios'
// import Cleave from 'cleave.js'
import Cleave from 'vue-cleave-component'
import { useInfo, useRide, validateField, cities, origins } from '../store'
import ToolTip from '@/components/ToolTip.vue'

export default {
  name: 'RideInfo',
  components: {
    ToolTip,
    Cleave
  },
  created () {
    if (!this.infoState.info.account) this.$router.replace('/')
    // get available dates
    this.axios.get('/booking/availabledates')
      .then(res => {
        // console.info(' :::: got dates', res)
        this.availableDates = res.data
      })
    for (let i = this.firstArrival; i <= this.lastArrival; i++) {
      this.pickupTimes = this.pickupTimes.concat(this.timeIntervals.map(int => `${(i % 12) || '12'}:${int} ${i < 12 ? 'AM' : 'PM'}`))
    }
  },
  mounted () {
    if (this.rideState.ride.price) {
      this.form.date = this.rideState.ride.date
      this.form.time = this.rideState.ride.time
      this.form.startAddress = this.rideState.ride.startAddress
      this.form.destinationAddress = this.rideState.ride.destinationAddress
      this.form.returnTrip = this.rideState.ride.returnTrip
      this.form.pickupTime = this.rideState.ride.pickupTime
      this.form.pickupAddress = this.rideState.ride.pickupAddress
      this.form.returnAddress = this.rideState.ride.returnAddress
      this.form.instructions = this.rideState.ride.instructions

      this.validate()
    }
  },
  data () {
    return {
      infoState: useInfo(),
      rideState: useRide(),
      addressTpl: {
        number: '',
        street: '',
        apt: '',
        city: '',
        postalCode: ''
      },
      form: {
        date: '',
        time: '',
        startAddress: {
          street: '',
          number: '',
          apt: '',
          city: '',
          postalCode: ''
        },
        destinationAddress: {
          street: '',
          number: '',
          apt: '',
          city: '',
          postalCode: ''
        },
        returnTrip: false,
        pickupTime: '',
        pickupAddress: 'same',
        returnAddress: 'same',
        instructions: ''
      },
      cities,
      origins,
      availableDates: [],
      pickupTimes: [],
      fields: {
        date: {
          valid: true,
          blurred: false,
          message: 'Need a Date',
          rules: ['required']
        },
        time: {
          valid: true,
          blurred: false,
          message: 'Need a Time',
          rules: ['required']
        },
        startAddress: {
          valid: true,
          blurred: false,
          message: 'Address must have a street, number, city and postal code',
          rules: ['address']
        },
        destinationAddress: {
          valid: true,
          blurred: false,
          message: 'Address must have a street, number, city and postal code',
          rules: ['address']
        },
        pickupAddress: {
          valid: true,
          blurred: false,
          message: 'Address must have a street, number, city and postal code',
          rules: ['address']
        },
        returnAddress: {
          valid: true,
          blurred: false,
          message: 'Address must have a street, number, city and postal code',
          rules: ['address']
        },
        pickupTime: {
          valid: true,
          blurred: false,
          message: 'Need a Time',
          rules: []
        },
      },
      firstArrival: 9,
      lastArrival: 17,
      firstPickup: 10,
      lastPickup: 18,
      timeIntervals: ['00', '15', '30', '45'],
      invalid: true,
      validityFlags: [],
      showInvalidSummary: false,
      invalidMessages: []
    }
  },
  methods: {
    // inputPostalCode (evt) {
    //   const c = new Cleave(evt.target, { blocks: [3, 3], delimiter: '-', uppercase: true }) // eslint ignore no-new
    //   if (!c) console.info(' ::: cleave not set?')
    // },
    validateField (fieldkey, evt) {
      if (typeof evt === 'string') evt = { target: { value: evt } }
      this.fields[fieldkey].blurred = true
      this.fields[fieldkey].valid = validateField(fieldkey, evt, this.fields)
      this.validate(evt)
    },
    validate (evt) {
      let valid = true
      this.validityFlags = []
      this.$nextTick(() => {
        if (!this.form.date) {
          valid = false
          this.validityFlags.push('date')
        }
        if (!this.form.time) {
          valid = false
          this.validityFlags.push('time')
        }
        if (!this.form.startAddress.number || !this.form.startAddress.street || !this.form.startAddress.city || !this.form.startAddress.postalCode) {
          valid = false
          this.validityFlags.push('startAddress')
        }
        if (!this.form.destinationAddress.number || !this.form.destinationAddress.street || !this.form.destinationAddress.city || !this.form.destinationAddress.postalCode) {
          valid = false
          this.validityFlags.push('destinationAddress')
        }

        if (this.form.returnTrip) {
          if (!this.form.pickupTime) {
            valid = false
            this.validityFlags.push('pickupTime')
          }
          if (this.form.pickupAddress !== 'same') {
            if (!this.form.pickupAddress.number || !this.form.pickupAddress.street || !this.form.pickupAddress.city || !this.form.pickupAddress.postalCode) {
              valid = false
              this.validityFlags.push('pickupAddress')
            }
          }
          if (this.form.returnAddress !== 'same') {
            if (!this.form.returnAddress.number || !this.form.returnAddress.street || !this.form.returnAddress.city || !this.form.returnAddress.postalCode) {
              valid = false
              this.validityFlags.push('returnAddress')
            }
          }
        }

        this.invalid = !valid
        if (this.showInvalidSummary && valid) this.showInvalidSummary = false
        return Promise.resolve(valid)
      })
    },
    next: async function () {
      if (this.invalid) {
        if (!this.validityFlags.length) this.validate()
        this.$nextTick(() => {
          this.invalidMessages = this.validityFlags.map(f => ({ field: f, message: this.fields[f].message }))
          for (const field of this.invalidMessages) {
            this.fields[field.field].valid = false
          }
        })
        this.showInvalidSummary = true
        // return
      } else {
        if (this.infoState.info.type !== 'transHelp') {
          const price = await this.axios.post('/booking/price', Object.assign(this.rideState.ride, this.form))
          this.form.price = price.data.total
        }
        // console.info(' ::: next and valid', this.form, this.infoState.info.value)
        this.form.arriveTimestamp = new Date(this.form.date + ' ' + this.form.time).getTime()
        if (this.form.returnTrip) this.form.pickupTimestamp = new Date(this.form.date + ' ' + this.form.pickupTime).getTime()
        // set form info
        this.rideState.setRide(this.form)
        this.$router.push({ name: 'Summary' })
      }
    }
  }
}
</script>
